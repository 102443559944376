module.exports = {
  siteTitle: 'Yucasi, Inc.', // <title>
  manifestName: 'Yucasi',
  manifestShortName: 'Website', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Yucasi, Inc.',
  subHeading:
    "New Website Coming Soon...",
  socialLinks: [
  ],
};
